
/// <reference path="../../declarations/mapkit/index.d.ts" />
import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import { ICoordinate, ICoordinateSpan, ICoordinateRegion, makeCoordinatePOJO, makeCoordinateSpanPOJO, makeCoordinateRegionPOJO } from "../../misc/mapkit-shim";
import Multiselect from "vue-multiselect"
import { debounce, mean, isEqual, round, uniqBy, sortBy } from "lodash";
import ListingSearchFilters from "../../components/listing-search-filters.vue";
import CheckboxItem from "../../components/checkbox-item.vue";
import SavedListingSearchPickerModal from "~/components/saved-listing-search-picker-modal.vue";
import ListingsInBoundingBoxQuery from "@/apollo/ListingsInBoundingBoxQuery.graphql";
import ListingsInPolylinePolygonQuery from "@/apollo/ListingsInPolylinePolygonQuery.graphql";
import ClusteredListingCountsQuery from "@/apollo/ClusteredListingCountsQuery.graphql";
import ClusteredListingCountsInPolylinePolygonQuery from "@/apollo/ClusteredListingCountsInPolylinePolygonQuery.graphql";
import ListingsByMlsNumberQuery from "@/apollo/ListingsByMlsNumberQuery.graphql";
import PropertiesByAddressQuery from "@/apollo/PropertiesByAddressQuery.graphql";
import ShortListingFromPropertyByIdQuery from "@/apollo/ShortListingFromPropertyByIdQuery.graphql";
import ListingQuery from "@/apollo/ListingQuery.graphql";
import SavedListingSearchQuery from "@/apollo/SavedListingSearchQuery.graphql";
import MarkListingSearchExecutionMutation from "@/apollo/MarkSavedListingSearchExecutionMutation.graphql";

import { Location } from "vue-router";
// tslint:disable-next-line:no-implicit-dependencies
import { MetaInfo } from "vue-meta";
import { Getter, State, Action } from "vuex-class";
import { RootState } from "../../store";
import CheapRuler from "cheap-ruler";
import simplify from "simplify-js"
import polyline from "@mapbox/polyline";
import { Intersect, Readonly } from "simplytyped";
import groupListingConnectionQueryResultDataAndErrors from "../../misc/group-listings-connection-query-result-data-and-errors";

import { ListingFilters, ListingWithErrors, NullableKeys } from "../../misc/interfaces";
import { ListingStatus, ListingTransactionType, ListingOwnershipType, ListingReaction, ResidentialPropertyStyleAttachment, GeolocatedListingOrderField, OrderDirection, ResidentialPropertyType, ListingFeedType } from "../../gql-typings/globalTypes";
import { ListingsByMlsNumberVariables, ListingsByMlsNumber as ListingsByMlsNumberResult } from "../../gql-typings/ListingsByMlsNumber";
import { ClusteredListingCountsVariables, ClusteredListingCounts_clustersWithCounts as ClusteredListingCounts } from "../../gql-typings/ClusteredListingCounts";
import { ClusteredListingCountsInPolylinePolygonVariables, ClusteredListingCountsInPolylinePolygon_clustersWithCounts as ClusteredListingCountsInPolylinePolygon } from "../../gql-typings/ClusteredListingCountsInPolylinePolygon";
import { PropertiesByAddressVariables, PropertiesByAddress as PropertiesByAddressResult, PropertiesByAddress_propertiesByAddress_edges_node as PropertiesByAddressNode } from "../../gql-typings/PropertiesByAddress";
import { ShortListingFromPropertyByIdVariables, ShortListingFromPropertyById as ShortListingFromPropertyByIdResult, ShortListingFromPropertyById_property_Property as ShortListingFromPropertyByIdPropertyNode } from "../../gql-typings/ShortListingFromPropertyById";
import { ListingsInBoundingBoxVariables, ListingsInBoundingBox_listings as ListingsConnection, ListingsInBoundingBox as ListingsInBoundingBoxResult } from "../../gql-typings/ListingsInBoundingBox";
import { ListingsInPolylinePolygonVariables, ListingsInPolylinePolygon as ListingsInPolylinePolygonResult } from "../../gql-typings/ListingsInPolylinePolygon";
import { ListingVariables as ListingQueryVariables, Listing as ListingQueryResult } from "../../gql-typings/Listing";
import { SavedListingSearchVariables as SavedListingSearchQueryVariables, SavedListingSearch as SavedListingSearchQueryResult, SavedListingSearch_savedListingSearch_SavedListingSearch as SavedListingSearchNode } from "@/gql-typings/SavedListingSearch";
import { MarkSavedListingSearchExecutionVariables, MarkSavedListingSearchExecution as MarkSavedListingSearchExecutionResult } from "@/gql-typings/MarkSavedListingSearchExecution";
import ListingsMap from "../../components/listings-map.vue";
import ListingPageListingsSidebar from "../../components/listing-page-listings-sidebar.vue";
import { ApolloQueryResult } from "apollo-client/core/types";
import isNotNull from "../../misc/is-not-null";
import {Route} from "vue-router/types/router";
import MobileInstallBanner from "~/components/mobile-install-banner.vue";
// import { RecordListingSearchSearchActorEventMutationVariables } from "~/gql-typings/RecordListingSearchSearchActorEventMutation";

type SearchAutocompleteResultWithCoordinate = mapkit.SearchAutocompleteResult & {coordinate: mapkit.Coordinate};
type NullableListingFilters = NullableKeys<ListingFilters>;

const valueIfDistinctElseNull = <T extends object, K extends keyof T>(newValues: T, compareTo: T, key: K): NullableKeys<T>[K] => {
	let newValue = newValues[key];

	if (Array.isArray(newValue)) {
		newValue = ([...newValue] as any[]).sort() as typeof newValue;
	}

	let compareToValue = compareTo[key];

	if (Array.isArray(compareToValue)) {
		compareToValue = ([...compareToValue] as any[]).sort() as typeof compareToValue;
	}

	const equal = isEqual(newValue, compareToValue);

	return !equal ? newValue : null;
};

const nullString = "∅";

const MAX_GPS_DIGITS = 4;
const DEFAULT_MAP_SPAN = 0.025;
const CLOSE_MAP_SPAN = 0.02;
const NEAREST_RADIUS = 5500;
const MLS_REGEX = /^([a-zA-Z]*[0-9\-]+){4,}$/;

const allInclusiveStatusesSet = new Set(Object.keys(ListingStatus).sort() as ListingStatus[]);

[ListingStatus.UNKNOWN, ListingStatus.COMING_SOON, ListingStatus.DELETE, ListingStatus.INCOMPLETE].forEach(s => allInclusiveStatusesSet.delete(s));

const allInclusiveSearchFilters: ListingFilters = {
	feedTypes: [ListingFeedType.MLS, ListingFeedType.BROKER_LIST],
	statuses: [...allInclusiveStatusesSet],
	transactionTypes: [ListingTransactionType.SALE, ListingTransactionType.LEASE],
	ownershipTypes: Object.keys(ListingOwnershipType).sort() as ListingOwnershipType[],
	viewerReactions: [],
	priceRangeMin: null,
	priceRangeMax: null,
	maintenanceFeeRangeMin: null,
	maintenanceFeeRangeMax: null,
	bedroomsRangeMin: null,
	bedroomsRangeMax: null,
	bedroomsPlusRangeMin: null,
	bedroomsPlusRangeMax: null,
	bathroomsRangeMin: null,
	bathroomsRangeMax: null,
	kitchensRangeMin: null,
	kitchensRangeMax: null,
	parkingSpacesRangeMin: null,
	parkingSpacesRangeMax: null,
	garageSpacesRangeMin: null,
	garageSpacesRangeMax: null,
	lotSizeWidthRangeMin: null,
	lotSizeWidthRangeMax: null,
	lotSizeDepthRangeMin: null,
	lotSizeDepthRangeMax: null,
	lotSizeAreaRangeMin: null,
	lotSizeAreaRangeMax: null,
	interiorAreaRangeMin: null,
	interiorAreaRangeMax: null,
	storeysRangeMin: null,
	storeysRangeMax: null,
	propertyTypes: Object.keys(ResidentialPropertyType).sort() as ResidentialPropertyType[],
	styleAttachments: [...(Object.keys(ResidentialPropertyStyleAttachment) as ResidentialPropertyStyleAttachment[]), null].sort(),
	hasWaterfrontAccess: null,
	daysAtMarketAvailabilityRangeMin: null,
	daysAtMarketAvailabilityRangeMax: null,
	hasOpenHouse: false,
}

const defaultSearchFilters: ListingFilters = {
	...allInclusiveSearchFilters,
	statuses: [ListingStatus.ACTIVE, ListingStatus.ACTIVE_UNDER_CONTRACT],
	transactionTypes: [ListingTransactionType.SALE],
};

interface BaseSearchMultiselectOption {
	type: "mls" | "autocomplete" | "property" | "search" | "saved_listing_search" | "saved_listing_searches"
	skip?: boolean;
	displayLines: string[];
}

interface CoordinatableSearchMultiselectOption extends BaseSearchMultiselectOption {
	type: "search" | "property" | "autocomplete";
	coordinate: ICoordinate;
}

interface SearchMultiselectSearchOption extends CoordinatableSearchMultiselectOption {
	type: "search";
}

interface SearchMultiselectPropertyOption extends CoordinatableSearchMultiselectOption {
	type: "property";
	property: PropertiesByAddressNode;
}

interface SearchMultiselectAutocompleteOption extends CoordinatableSearchMultiselectOption {
	type: "autocomplete";
	autocomplete: mapkit.SearchAutocompleteResult;
}

interface SearchMultiselectMLSNumberOption extends BaseSearchMultiselectOption {
	type: "mls";
}

interface SearchMultiselectSavedListingSearchOption extends BaseSearchMultiselectOption {
	type: "saved_listing_search";
	savedListingSearch: SavedListingSearchNode;
}

interface SearchMultiselectSavedSearchesOption extends BaseSearchMultiselectOption {
	type: "saved_listing_searches";
}

type SearchMultiselectOption = SearchMultiselectPropertyOption | SearchMultiselectAutocompleteOption | SearchMultiselectMLSNumberOption | SearchMultiselectSearchOption | SearchMultiselectSavedListingSearchOption | SearchMultiselectSavedSearchesOption;

interface SearchMultiselectOptionsPropertiesGroup {
	section: "Properties";
	results: SearchMultiselectPropertyOption[];
}

interface SearchMultiselectOptionsNearGroup {
	section: "Near";
	results: (SearchMultiselectAutocompleteOption | SearchMultiselectSearchOption)[];
}

interface SearchMultiselectOptionsMLSNumberGroup {
	section: "MLS®#";
	results: SearchMultiselectMLSNumberOption[];
}

interface SearchMultiselectOptionsHeaderlessGroup {
	section: "";
	results: SearchMultiselectSavedSearchesOption[];
}

type SearchMultiselectOptionsGroup = SearchMultiselectOptionsPropertiesGroup | SearchMultiselectOptionsNearGroup | SearchMultiselectOptionsMLSNumberGroup | SearchMultiselectOptionsHeaderlessGroup;

type Query = Exclude<Location["query"], undefined>;

// extract this to a common file
type AnnotationSelectEvent = Event & {
	target: mapkit.Annotation,
	type: "select",
}

const propertyNodeToPropertySearchInput = (property: PropertiesByAddressNode): SearchMultiselectPropertyOption => {
	const propertyCoord = property.latLon!;
	const coordinate = makeCoordinatePOJO(propertyCoord.lat, propertyCoord.lon);

	return {
		type: "property",
		displayLines: [
			property.streetAddressFormatted,
			[property.city, property.region, property.postalCode].filter((p): p is string => Boolean(p)).join(", "),
		].filter((p): p is NonNullable<typeof p> => Boolean(p)),
		coordinate,
		property,
	};
}

// hack to persist queryRegion between reuse of component
let queryRegion: ICoordinateRegion | null  = null;

// const toBase64 = (input: string) => process.server ? Buffer.from(input).toString("base64") : btoa(input);
const fromBase64 = (input: string) => process.server ? Buffer.from(input, "base64").toString("utf8") : atob(input);

@Component<ListingsIndexPage>({
	layout: "default_fixed-frame",

	head(this: ListingsIndexPage): MetaInfo {
		let title: string = "";

		// TODO: improve metadata

		const meta: MetaInfo["meta"] = [];
		const imageMeta: MetaInfo["meta"] = [];

		if (this.mlsValue) {
			title = `Find listings for MLS®# ${this.mlsValue}`;
		} else if (this.searchInput) {
			const [firstLine, secondLine] = this.searchInput.displayLines;

			title = `Find listings near ${firstLine}`;

			if (secondLine) {
				title += ` (${secondLine})`;
			}
		} else {
			title = "Find listings in your area";

			if (this.polygonPolyline) {
				const mkSnapshotURL = new URL("/mk_snapshot", this.$config.HOST_WEB);

				const overlay = {
					lineWidth: 2,
					strokeColor: "9934ff",
					points: this.polygonPolyline,
				};

				const width = 610;
				const height = 320;
				const scale = 2;

				mkSnapshotURL.searchParams.set("scale", `${scale}`);
				mkSnapshotURL.searchParams.set("size", `${width}x${height}`);
				mkSnapshotURL.searchParams.set("poi", "0");
				mkSnapshotURL.searchParams.set("center", "auto");
				mkSnapshotURL.searchParams.set("overlays", JSON.stringify([overlay]));

				imageMeta.push({
					hid: "og:image",
					property: "og:image",
					content: mkSnapshotURL.toString(),
				});

				imageMeta.push({
					hid: "og:image:width",
					property: "og:image:width",
					content: `${width * scale}`,
				});

				imageMeta.push({
					hid: "og:image:height",
					property: "og:image:height",
					content: `${height * scale}`,
				});

				imageMeta.push({
					hid: "twitter:card",
					name: "twitter:card",
					content: "summary"
				});
			}
		}

		// fallback image for FB
		if (!imageMeta.length) {
			// absolute URL, not a relative one, for Twitter's sake
			const ogImageUrl = new URL(require("~/assets/share_get-app_default_black.png"), this.$config.HOST_WEB);

			imageMeta.push({
				hid: "og:image",
				property: "og:image",
				content: ogImageUrl.toString(),
			});

			imageMeta.push({
				hid: `og:image:width`,
				property: "og:image:width",
				content: "1280",
			});

			imageMeta.push({
				hid: `og:image:height`,
				property: "og:image:height",
				content: "720",
			});
		}

		meta.push(...imageMeta);

		meta.push({
			hid: "og:title",
			property: "og:title",
			content: title,
		})

		const description = "Real estate search made simple.";

		meta.push({
			hid: "og:description",
			property: "og:description",
			content: description,
		});

		meta.push({
			hid: "description",
			name: "description",
			content: description,
		});

		return {
			title,
			meta,
			link: [
				// We use $route.path since we don't use query parameters
				{ rel: "canonical", href: this.canonicalUrl },
			],
		};
	},

	components: {
		Multiselect,
		CheckboxItem,
		ListingSearchFilters,
		ListingsMap,
		ListingPageListingsSidebar,
		SavedListingSearchPickerModal,
		MobileInstallBanner,
	},

	watchQuery: [
		"saved_listing_search_id",
		"mls_number",
		"property_id",
		"lat_lon",
		"transaction",
		"ownership",
		"feed",
		"status",
		"price",
		"fee",
		"beds",
		"baths",
		"kitchens",
		"parkings",
		"garages",
		"lot_size_width",
		"lot_size_depth",
		"lot_size_area",
		"interior_area",
		"storeys",
		"attachment",
		"property_type",
		"has_waterfront_access",
		"only_liked",
		"open_house",
		"polygon"
	],

	async asyncData({query, app}) {
		const apollo = app.apolloProvider.defaultClient;
		const data: Partial<ListingsIndexPage> = {};

		if (query.view === "cards") {
			data.mapOnTop = false;
		} else if (query.view === "map") {
			data.mapOnTop = true;
		}

		if (query.mls_number) {
			data.mlsValue = query.mls_number as string;

			data.searchInput = {
				type: "mls",
				displayLines: [data.mlsValue],
			};
		} else if (typeof query.property_id === "string") {
			const result = await apollo.query<ShortListingFromPropertyByIdResult, ShortListingFromPropertyByIdVariables>({
				query: ShortListingFromPropertyByIdQuery,
				variables: {
					id: query.property_id,
				},
				errorPolicy: "all",
			});

			if (result.data.property?.__typename === "Property") {
				const property = result.data.property;
				const searchInput = propertyNodeToPropertySearchInput(property);
				const coordinate = searchInput.coordinate;

				const listingsWithErrors = groupListingConnectionQueryResultDataAndErrors({listings: property.listings, errors: result.errors}, ["property", "listings"]);

				data.propertySearchProperty = property;
				data.propertySearchListingWithError = listingsWithErrors[0];
				data.searchInput = searchInput;
				data.initialMapCoordinate = coordinate;
			}
		} else if (query.lat_lon) {
			const [lat, lon] = (query.lat_lon as string || "").split(",").map(parseFloat);

			if (!Number.isNaN(lat) && !Number.isNaN(lon)) {
				const coordinate = makeCoordinatePOJO(lat, lon);

				data.initialMapCoordinate = coordinate;

				if (typeof query.lat_lon_hint === "string") {
					data.currentSearchCoordinate = coordinate;
					data.searchInput = {
						type: "search",
						coordinate: coordinate,
						displayLines: [query.lat_lon_hint],
						skip: true,
					};
				}
			}
		} else if (query.current_location) {
			data.trackingUserLocation = true;
			data.showsUserLocation = true;
		}

		if (typeof query.saved_listing_search_id === "string") {
			let result;

			try {
				result = await apollo.query<SavedListingSearchQueryResult, SavedListingSearchQueryVariables>({
					query: SavedListingSearchQuery,
					variables: {
						id: query.saved_listing_search_id
					},
				});
			} catch (e) {
				// might be trying to access a saved search belonging to another user
				// TODO: it might be better to also check if the user is logged in at this point
				if (!(e as Error).message.includes("Must be logged in to access Saved Listing Searches")) {
					throw e;
				}

				result = null;
			}

			if (result?.data.savedListingSearch?.__typename === "SavedListingSearch") {
				const savedListingSearch = result.data.savedListingSearch;

				const searchInput: SearchMultiselectSavedListingSearchOption = {
					type: "saved_listing_search",
					savedListingSearch,
					displayLines: [
						savedListingSearch.title,
					]
				}

				data.savedListingSearch = savedListingSearch;
				data.searchInput = searchInput;
			}
		}

		data.searchFilters = {
			...defaultSearchFilters,
		};

		if (query.feed) {
			const feedTypes = Array.isArray(query.feed) ? query.feed : [query.feed];

			data.searchFilters.feedTypes = feedTypes
			.filter((t): t is ListingFeedType => t !== null && t in ListingFeedType);
		}

		if (query.status) {
			const statusTypes = Array.isArray(query.status) ? query.status : [query.status];

			data.searchFilters.statuses = statusTypes
			.filter((t): t is ListingStatus => t !== null && t in ListingStatus);
		}

		if (query.transaction) {
			const transactionTypes = Array.isArray(query.transaction) ? query.transaction : [query.transaction];

			data.searchFilters.transactionTypes = transactionTypes
			.filter((t): t is ListingTransactionType => t !== null && t in ListingTransactionType);
		}

		if (query.ownership) {
			const ownershipTypes = Array.isArray(query.ownership) ? query.ownership : [query.ownership];

			data.searchFilters.ownershipTypes = ownershipTypes
			.filter((t): t is ListingOwnershipType => t !== null && t in ListingOwnershipType);
		}

		if (query.property_type) {
			const propertyTypes = Array.isArray(query.property_type) ? query.property_type : [query.property_type];

			data.searchFilters.propertyTypes = propertyTypes
			.filter((t): t is ResidentialPropertyType => t !== null && t in ResidentialPropertyType);
		}

		if (query.attachment) {
			const styleAttachments = Array.isArray(query.attachment) ? query.attachment : [query.attachment];

			data.searchFilters.styleAttachments = styleAttachments
			.map(t => t === nullString ? null : t)
			.filter((t): t is (ResidentialPropertyStyleAttachment | null) => t === null || t in ResidentialPropertyStyleAttachment)
			.sort();
		}

		const rangeStringInQueryToRange = (name: string, prefix: string) => {
			const queryValue = query[name];

			if (typeof queryValue !== "string") {
				return;
			}

			const rangeMatch = queryValue.match(/^(?<begin>[\d\.]*),(?<end>[\d\.]*)$/);

			if (rangeMatch) {
				const {begin: beginString, end: endString} = rangeMatch.groups!;

				const begin = parseFloat(beginString);
				const end = parseFloat(endString);

				if (!Number.isNaN(begin)) {
					(data.searchFilters as any)[`${prefix}Min`] = begin;
				}

				if (!Number.isNaN(end)) {
					(data.searchFilters as any)[`${prefix}Max`] = end;
				}
			}
		};

		rangeStringInQueryToRange("price", "priceRange");
		rangeStringInQueryToRange("fee", "maintenanceFeeRange");
		rangeStringInQueryToRange("beds", "bedroomsRange");
		rangeStringInQueryToRange("beds_plus", "bedroomsPlusRange");
		rangeStringInQueryToRange("baths", "bathroomsRange");
		rangeStringInQueryToRange("kitchens", "kitchensRange");
		rangeStringInQueryToRange("parkings", "parkingSpacesRange");
		rangeStringInQueryToRange("garages", "garageSpacesRange");
		rangeStringInQueryToRange("lot_size_width", "lotSizeWidthRange");
		rangeStringInQueryToRange("lot_size_depth", "lotSizeDepthRange");
		rangeStringInQueryToRange("lot_size_area", "lotSizeAreaRange");
		rangeStringInQueryToRange("interior_area", "interiorAreaRange");
		rangeStringInQueryToRange("storeys", "storeysRange");
		rangeStringInQueryToRange("days_back", "daysAtMarketAvailabilityRange");

		if (query.has_waterfront_access === "1") {
			data.searchFilters.hasWaterfrontAccess = true;
		}

		if (query.open_house === "1") {
			data.searchFilters.hasOpenHouse = true;
		}

		if (query.only_liked === "1") {
			data.searchFilters.viewerReactions = [ListingReaction.LIKE];
		}

		if (typeof query.polygon === "string") {
			let polylineString;

			// test if it's base64url encoded
			// otherwise, it's using the old URL escaping method
			if (/^(?:[A-Za-z0-9\-\_])+$/.test(query.polygon)) {
				// convert to base64 string
				const base64PolylinePolygon = query.polygon.replace(/\-/g, "+").replace(/_/g, "/");

				// decode base64 string
				polylineString = fromBase64(base64PolylinePolygon);
			} else {
				polylineString = decodeURIComponent(query.polygon);
			}

			const polygon = polyline.decode(polylineString);
			data.overlayCoordinates = polygon.map(([lat, lon]): ICoordinate => ({latitude: lat, longitude: lon}));
		}

		return data;
	},

	apollo: {
		clustersWithCounts: {
			prefetch: false,
			query() {
				return this.polygonPolyline ? ClusteredListingCountsInPolylinePolygonQuery : ClusteredListingCountsQuery;
			},
			variables(): ClusteredListingCountsVariables | ClusteredListingCountsInPolylinePolygonVariables {
				const {polygonPolyline, mapBbox} = this;

				let variables;

				if (polygonPolyline) {
					variables = {polyline: polygonPolyline};
				} else if (mapBbox) {
					variables = {bbox: mapBbox};
				} else {
					throw new Error("Region not present");
				}

				return {
					...variables,
					...this.commonListingsVariables
				};
			},
			skip() {
				return !(this.displayClusters && this.mapBbox);
			},
		},
		listings: {
			prefetch: false,
			query() {
				if (this.mlsValue) {
					return ListingsByMlsNumberQuery;
				} else if (this.polygonPolyline) {
					return ListingsInPolylinePolygonQuery;
				} else if (this.queryBbox) {
					return ListingsInBoundingBoxQuery;
				} else {
					throw new Error("Unable to create a query");
				}
			},
			variables() {
				return this.listingsVariables;
			},
			skip() {
				return !(
					// MLS value
					Boolean(this.mlsValue) ||
					// OR:
					(
						// display clusters are off and not drawing a shape
						(!this.displayClusters && !this.isDrawing) &&
						// AND:
						(
							// has bbox
							Boolean(this.queryBbox) ||
							// or polygon
							Boolean(this.polygonPolyline)
						)
					)
				);
			},
			async result(result: ApolloQueryResult<ListingsInPolylinePolygonResult | ListingsInBoundingBoxResult | ListingsByMlsNumberResult>) {
				const {data, errors} = result;

				if (data?.listings?.edges) {
					this.listingsWithErrors = groupListingConnectionQueryResultDataAndErrors({
						listings: data.listings,
						errors,
					});

					// consider moving fetch more to some other location
					if (!data.listings.pageInfo.hasNextPage || data.listings.edges.length >= 250) {
						return;
					}

					await this.$apollo.queries.listings.fetchMore({
						variables: {
							after: data.listings.pageInfo.endCursor,
						},
						updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
							if (!(this.listings?.edges?.length === data.listings.edges?.length)) {
								// dirty check that the this is still the same query.
								return;
							}

							const {__typename, edges} = previousResult.listings;
							const newListings = fetchMoreResult.listings;
							const {pageInfo} = newListings;

							return {
								listings: {
									__typename,
									edges: [...edges, ...newListings.edges],
									pageInfo,
								},
							};
						},
					});
				}
			},
			error(error) {
				if (error.networkError) {
					this.$sentry?.captureException(error.networkError);
				}
			}
		}
	},
})
export default class ListingsIndexPage extends Vue {
	/**
	 * Constants
	 */
	readonly defaultSearchFilters: ListingFilters = defaultSearchFilters;
	readonly host: string = (process as any).server ? this.$ssrContext.req.headers.host : window.location.host;

	/**
	 * Vuex state
	 */
	@State("viewer") viewer!: RootState["viewer"] | null;
	@Getter("agent") readonly sessionAgent!: RootState["assignedAgent"];
	@State("isMobile") readonly isMobile!: boolean;
	@State("lastMapCoordinateRegion") readonly defaultMapCoordinateRegion!: ICoordinateRegion;
	@State readonly mapkitLoaded!: boolean;

	/**
	 * Layout refs
	 */
	@Ref("map")	readonly listingsMap!: ListingsMap | null;
	@Ref("map-holder") readonly mapHolderEl!: Element | null;

	/**
	 * Apollo outlets
	 */
	listings: ListingsConnection | null = null;
	clustersWithCounts: (ClusteredListingCounts | ClusteredListingCountsInPolylinePolygon)[] | null = null;
	filteredListingIds: string[] | null = null;

	/**
	 * Main props
	 */
	listingsWithErrors: ListingWithErrors[] | null = null;
	mlsValue: string | null = null;
	propertySearchProperty: ShortListingFromPropertyByIdPropertyNode | null = null;
	propertySearchListingWithError: ListingWithErrors | null = null;
	savedListingSearch: SavedListingSearchNode | null = null;
	showsUserLocation: boolean = Boolean(this.getMapInstance()?.userLocationAnnotation);
	searchFilters: ListingFilters = {...this.defaultSearchFilters};

	/**
	 * Page session props
	 */
	trackingUserLocation: boolean = false;

	/**
	 * Mapkit search props
	 */
	search!: mapkit.Search | null;
	searchAutocompleteDebounced!: any; // todo: fix this type
	searchInput: SearchMultiselectOption | null = null;
	searchAutocompleteOptions: SearchMultiselectOptionsGroup[] | null = null;
	autocompleteIsLoading: boolean = false;
	latestSearchAutocompleteQuery: string = "";

	@Watch("mapkitLoaded", {
		immediate: true,
	})
	onMapkitLoadedChange(val: boolean) {
		if (val && !this.search) {
			this.search = new mapkit.Search();
		}

		if (val) {
			this.setUpMap();
		}
	}

	/**
	 * Coordinates and regions
	 */
	initialMapCoordinate: ICoordinate | null = null;

	mapRegion: ICoordinateRegion | null = (() => {
		const region = this.getCurrentMapkitRegion();

		if (region) {
			return this.coordinateRegionToCoordinateRegion(region);
		}

		return null;
	})();

	queryRegion: ICoordinateRegion | null = queryRegion;

	currentSearchCoordinate: ICoordinate | null = null;
	userLocationCoordinate: ICoordinate | null = null;

	/**
	 * Layout
	 */
	hoveredListingId: string = "";
	selectedListingId: string = "";
	showFilters: boolean = false;
	mapOnTop: boolean = true;

	get mapPadding(): mapkit.Padding | null {
		if (process.server || !this.mapkitLoaded) {
			return null;
		}

		const {isMobile} = this;

		return new mapkit.Padding((isMobile ? 0 : 72) + 60, 0, 0, 0);
	}

	private coordinateToMapkitCoordinate(coordinate: ICoordinate): mapkit.Coordinate | null;
	private coordinateToMapkitCoordinate(coordinate: null): null;
	private coordinateToMapkitCoordinate(coordinate: ICoordinate | null): mapkit.Coordinate | null;
	private coordinateToMapkitCoordinate(coordinate: ICoordinate | null): mapkit.Coordinate | null {
		return this.mapkitLoaded && coordinate ?
			(coordinate instanceof mapkit.Coordinate ?
				coordinate :
				new mapkit.Coordinate(coordinate.latitude, coordinate.longitude)) :
			null;
	}

	/**
	 * Convert a coordinate (including mapkit Coordinates) to a POJO
	 */
	private coordinateToCoordinate(coordinate: ICoordinate): ICoordinate;
	private coordinateToCoordinate(coordinate: null): null;
	private coordinateToCoordinate(coordinate: ICoordinate | null): ICoordinate | null;
	private coordinateToCoordinate(coordinate: ICoordinate | null): ICoordinate | null {
		return coordinate ? makeCoordinatePOJO(coordinate.latitude, coordinate.longitude) : null;
	}

	private coordinateSpanToMapkitCoordinateSpan(span: ICoordinateSpan): mapkit.CoordinateSpan | null;
	private coordinateSpanToMapkitCoordinateSpan(span: null): null;
	private coordinateSpanToMapkitCoordinateSpan(span: ICoordinateSpan | null): mapkit.CoordinateSpan | null;
	private coordinateSpanToMapkitCoordinateSpan(span: ICoordinateSpan | null): mapkit.CoordinateSpan | null {
		return this.mapkitLoaded && span ?
			(span instanceof mapkit.CoordinateSpan ?
				span :
				new mapkit.CoordinateSpan(span.latitudeDelta, span.longitudeDelta)) :
			null;
	}

	/**
	 * Convert a coordinate (including mapkit Coordinates) to a POJO
	 */
	/*private coordinateSpanToCoordinateSpan(span: ICoordinateSpan | null) {
		return this.mapkitLoaded && span ? makeCoordinateSpanPOJO(span.latitudeDelta, span.longitudeDelta) : null;
	}*/

	private coordinateRegionToMapkitCoordinateRegion(region: ICoordinateRegion | null) {
		if (!(this.mapkitLoaded && region)) {
			return null;
		}

		if (region instanceof mapkit.CoordinateRegion) {
			return region;
		}

		const center = this.coordinateToMapkitCoordinate(region.center);
		const span = this.coordinateSpanToMapkitCoordinateSpan(region.span)

		if (center && span) {
			return new mapkit.CoordinateRegion(
				center,
				span
			)
		}

		return null;
	}

	private coordinateRegionToCoordinateRegion(region: ICoordinateRegion | null) {
		return region ? makeCoordinateRegionPOJO(region.center, region.span) : null;
	}

	get mapCoordinate(): ICoordinate | null {
		return this.mapRegion?.center || null;
	}

	set mapCoordinate(coordinate: ICoordinate | null) {
		this.mapRegion = coordinate ? makeCoordinateRegionPOJO(coordinate, this.mapRegion?.span || this.getSpanForMaxDegrees(CLOSE_MAP_SPAN)) : null;
	}

	get mapkitCoordinate(): mapkit.Coordinate | null {
		return this.coordinateToMapkitCoordinate(this.mapCoordinate);
	}

	set mapkitCoordinate(coordinate: mapkit.Coordinate | null) {
		this.mapCoordinate = this.coordinateToCoordinate(coordinate);
	}

	get mapkitCurrentSearchCoordinate(): mapkit.Coordinate | null {
		return this.coordinateToMapkitCoordinate(this.currentSearchCoordinate);
	}

	get mapkitUserLocationCoordinate(): mapkit.Coordinate | null {
		return this.coordinateToMapkitCoordinate(this.userLocationCoordinate);
	}

	set mapkitUserLocationCoordinate(coordinate: mapkit.Coordinate | null) {
		this.userLocationCoordinate = this.coordinateToCoordinate(coordinate);
	}

	get mapkitRegion(): mapkit.CoordinateRegion | null {
		return this.coordinateRegionToMapkitCoordinateRegion(this.mapRegion);
	}

	set mapkitRegion(region: mapkit.CoordinateRegion | null) {
		this.mapRegion = this.coordinateRegionToCoordinateRegion(region);
	}

	get mapkitQueryRegion(): mapkit.CoordinateRegion | null {
		return this.coordinateRegionToMapkitCoordinateRegion(this.queryRegion);
	}

	set mapkitQueryRegion(region: mapkit.CoordinateRegion | null) {
		this.queryRegion = this.coordinateRegionToCoordinateRegion(region);
	}

	get mapSpanRatio() {
		const {mapRegion: region, mapHolderEl} = this;

		if (!region) {
			if (mapHolderEl) {
				return (mapHolderEl.clientHeight - (this.mapPadding?.top || 0)) / mapHolderEl.clientWidth;
			}

			return 1;
		}

		return region.span.latitudeDelta / region.span.longitudeDelta;
	}

	private calculateOverlapOfRegions(rA: mapkit.CoordinateRegion, rB: mapkit.CoordinateRegion | mapkit.BoundingRegion) {
		const boundingRegionA = rA.toBoundingRegion();
		const boundingRegionB = rB instanceof mapkit.BoundingRegion ? rB : rB.toBoundingRegion();

		const {northLatitude: ay2, southLatitude: ay1, westLongitude: ax1, eastLongitude: ax2} = boundingRegionA;
		const {northLatitude: by2, southLatitude: by1, westLongitude: bx1, eastLongitude: bx2} = boundingRegionB;

		const dxa = ax2 - ax1;
		const dya = ay2 - ay1;

		//const dxb = bx2 - bx1;
		//const dyb = by2 - by1;

		const areaA = Math.abs(dxa * dya);
		//const areaB = Math.abs(dxb * dyb);

		// calculate the area of the two overlapping shapes
		const xOverlap = Math.max(0, Math.min(ax2, bx2) - Math.max(ax1, bx1))
		const yOverlap = Math.max(0, Math.min(ay2, by2) - Math.max(ay1, by1));
		const overlapArea = xOverlap * yOverlap;

		// calculate the % that the overlap makes up of the first area
		const percentOverlap = overlapArea / areaA;

		//const areaUnion = areaA + areaB - aOverlap;
		//const overlap = aOverlap / areaUnion;

		return percentOverlap;
	}

	get polygonPolylineOfQueryRegion() {
		const {mapkitQueryRegion, mapkitRegion} = this;
		const region = mapkitQueryRegion || mapkitRegion;

		if (!region) {
			return null;
		}

		const boundingRegion = region.toBoundingRegion();

		const {northLatitude, southLatitude, westLongitude, eastLongitude} = boundingRegion;

		const neCoordinate = new mapkit.Coordinate(northLatitude, eastLongitude);
		const seCoordinate = new mapkit.Coordinate(southLatitude, eastLongitude);
		const swCoordinate = new mapkit.Coordinate(southLatitude, westLongitude);
		const nwCoordinate = new mapkit.Coordinate(northLatitude, westLongitude);

		const coordinates = [neCoordinate, seCoordinate, swCoordinate, nwCoordinate, neCoordinate];

		const encodedPolyline = polyline.encode(
			coordinates
			.map(c => [c.latitude, c.longitude])
		);

		return encodedPolyline;
	}

	get mapAndPolygonOverlap() {
		const {mapkitRegion, polygonOverlayCoordinates} = this;

		if (!mapkitRegion || !polygonOverlayCoordinates) {
			return null;
		}

		const lats = polygonOverlayCoordinates.map(c => c.latitude);
		const lons = polygonOverlayCoordinates.map(c => c.longitude);
		const minLat = Math.min(...lats);
		const minLon = Math.min(...lons);
		const maxLat = Math.max(...lats);
		const maxLon = Math.max(...lons);

		const polygonBoundingRegion = new mapkit.BoundingRegion(maxLat, maxLon, minLat, minLon);

		const overlap = this.calculateOverlapOfRegions(mapkitRegion, polygonBoundingRegion);

		return overlap;
	}

	get mapAndQueryRegionsOverlap() {
		const {mapkitRegion, mapkitQueryRegion} = this;

		if (!mapkitRegion || !mapkitQueryRegion) {
			return null;
		}

		const overlap = this.calculateOverlapOfRegions(mapkitRegion, mapkitQueryRegion);

		return overlap;
	}

	get mapCoordinateRegionChanged(): boolean {
		const {mapAndQueryRegionsOverlap: queryOverlap, mapAndPolygonOverlap: polygonOverlap} = this;

		if (polygonOverlap !== null) {
			return polygonOverlap < 0.3;
		} else if (queryOverlap !== null) {
			return queryOverlap < 0.6;
		}

		return false;
	}

	/**
	 * The "radius" of the bounding region, in metres
	 */
	get mapBoundingRegionRadius(): number {
		const {mapkitRegion} = this;

		if (!mapkitRegion) {
			return Number.MAX_SAFE_INTEGER;
		}

		const ruler = new CheapRuler(mapkitRegion.center.latitude, "meters");
		const boundingRegion = mapkitRegion.toBoundingRegion();

		const {
			northLatitude: lat1, eastLongitude: lon1,
			southLatitude: lat2, westLongitude: lon2
		} = boundingRegion;

		return ruler.distance([lon1, lat1], [lon2, lat2]) / 2;
	}

	get displayClusters(): boolean {
		return !this.mlsValue && this.mapBoundingRegionRadius >= NEAREST_RADIUS;
	}

	@Watch("displayClusters", {
		immediate: true,
	})
	onDisplayClustersChange(val: boolean, oldVal: boolean | undefined) {
		if (val && oldVal !== undefined) {
			this.queryRegion = null;
			this.currentSearchCoordinate = null;
		}
	}

	regionToBBox(region: mapkit.CoordinateRegion) {
		const round = (degrees: number) => parseFloat(degrees.toFixed(4));

		const bounding = region.toBoundingRegion();
		const {southLatitude: slat, westLongitude: wlon, northLatitude: nlat, eastLongitude: elon} = bounding;
		const sw = {lat: round(slat), lon: round(wlon)};
		const ne = {lat: round(nlat), lon: round(elon)};
		const bbox = {sw, ne};

		return bbox;
	}

	get mapBbox() {
		const {mapkitRegion: region} = this;

		if (!region) {
			return null;
		}

		return this.regionToBBox(region);
	}

	get queryBbox() {
		const {mapkitQueryRegion: region} = this;

		if (!region) {
			return null;
		}

		return this.regionToBBox(region);
	}

	get allListingsWithErrors() {
		const {listingsWithErrors, propertySearchListingWithError} = this;

		if (!process.client || listingsWithErrors === null) {
			return [];
		}

		let allListingsWithErrors = [...listingsWithErrors];

		if (propertySearchListingWithError) {
			allListingsWithErrors.unshift(propertySearchListingWithError);
		}

		// filter out listings with the same ID (property listing + the listing from the property, again)
		allListingsWithErrors = uniqBy(allListingsWithErrors, (edge) => edge?.id);

		return Readonly(allListingsWithErrors);
	}

	get filteredListings() {
		const {allListingsWithErrors, filteredListingIds, propertySearchListingWithError} = this;

		if (!allListingsWithErrors.length) {
			return null;
		}

		let list = [...allListingsWithErrors];

		list = sortBy(list, [
			// if the edge is for a property search listing, sort that first, regardless if it's visible or not.
			(edge) => edge.id === propertySearchListingWithError?.id ? -1 : 1,
			// sort edges by those with listings first,
			(edge) => edge.listing ? -1 : 1,
		]);

		if (filteredListingIds?.length) {
			list = list
			.filter(pair => pair.id && filteredListingIds.includes(pair.id));
		}

		return list;
	}

	get nullableSearchFiltersIfDefault(): NullableListingFilters {
		const {searchFilters} = this;

		return (Object.keys(searchFilters) as (keyof ListingFilters)[])
		.reduce((object, key) => {
			(object[key] as any) = valueIfDistinctElseNull(searchFilters, defaultSearchFilters, key);

			return object;
		}, {} as NullableListingFilters);
	}

	get nullableSearchFiltersIfInsignificant(): NullableListingFilters {
		const {searchFilters} = this;

		return (Object.keys(searchFilters) as (keyof ListingFilters)[])
		.reduce((object, key) => {
			(object[key] as any) = valueIfDistinctElseNull(searchFilters, allInclusiveSearchFilters, key);

			return object;
		}, {} as NullableListingFilters);
	}

	get commonListingsVariables(): Intersect<ListingsInBoundingBoxVariables, ListingsInPolylinePolygonVariables> {
		const {nullableSearchFiltersIfInsignificant: searchFilters} = this;

		const rangeInputForPrefix = (prefix: string, round: boolean = true): Intersect<ListingsInBoundingBoxVariables, ListingsInPolylinePolygonVariables>["maintenanceFeeRange"] => {
			const minKey = `${prefix}Min`;
			const maxKey = `${prefix}Max`;

			const minValue = (searchFilters as any)[minKey] as number | null;
			const maxValue = (searchFilters as any)[maxKey] as number | null;

			const roundIfNeeded = (value: number |null) => round && typeof value === "number" ? Math.round(value) : value;

			return typeof minValue === "number" || typeof maxValue === "number" ? {
				begin: roundIfNeeded(minValue) ?? null,
				end: roundIfNeeded(maxValue) ?? null,
			} : null;
		};

		return {
			feedTypes: searchFilters.feedTypes,
			statuses: searchFilters.statuses,
			transactionTypes: searchFilters.transactionTypes,
			ownershipTypes: searchFilters.ownershipTypes,
			viewerReactions: searchFilters.viewerReactions,
			priceRange: rangeInputForPrefix("priceRange"),
			maintenanceFeeRange: rangeInputForPrefix("maintenanceFeeRange"),
			bedroomsRange: rangeInputForPrefix("bedroomsRange"),
			bedroomsPlusRange: rangeInputForPrefix("bedroomsPlusRange"),
			bathroomsRange: rangeInputForPrefix("bathroomsRange"),
			kitchensRange: rangeInputForPrefix("kitchensRange"),
			parkingSpacesRange: rangeInputForPrefix("parkingSpacesRange"),
			garageSpacesRange: rangeInputForPrefix("garageSpacesRange"),
			lotSizeWidthRange: rangeInputForPrefix("lotSizeWidthRange", false),
			lotSizeDepthRange: rangeInputForPrefix("lotSizeDepthRange", false),
			lotSizeAreaRange: rangeInputForPrefix("lotSizeAreaRange", false),
			interiorAreaRange: rangeInputForPrefix("interiorAreaRange", false),
			storeysRange: rangeInputForPrefix("storeysRange", false),
			propertyTypes: searchFilters.propertyTypes,
			styleAttachments: searchFilters.styleAttachments,
			hasWaterfrontAccess: searchFilters.hasWaterfrontAccess,
			daysAtMarketAvailabilityRange: rangeInputForPrefix("daysAtMarketAvailabilityRange"),
			hasOpenHouse: searchFilters.hasOpenHouse,
		};
	}

	get listingsVariables(): ListingsInBoundingBoxVariables | ListingsInPolylinePolygonVariables | ListingsByMlsNumberVariables {
		const baseVariables = {
			after: undefined,
			first: 96,
		};

		const {queryBbox: bbox, polygonPolyline: polyline, mlsValue: mlsNumber, savedListingSearch} = this;

		if (mlsNumber) {
			return {
				...baseVariables,
				mlsNumber,
			}
		} else if (bbox || polyline) {
			const listingsVariables: Intersect<ListingsInBoundingBoxVariables, ListingsInPolylinePolygonVariables> = {
				...baseVariables,
				...this.commonListingsVariables,
			};

			if (polyline) {
				const field = savedListingSearch ? GeolocatedListingOrderField.DAYS_AT_MARKET_AVAILABILITY : GeolocatedListingOrderField.DISTANCE_TO_CENTROID;

				return {
					...listingsVariables,
					polyline,
					orderBy: {
						field,
						direction: OrderDirection.ASCENDING,
					},
				};
			} else if (bbox) {
				return {
					...listingsVariables,
					bbox,
				};
			}
		}

		throw new Error("Unable to create query variables");
	}

	get currentSearchCoordinateString(): string | null {
		return this.currentSearchCoordinate ? [
			this.currentSearchCoordinate.latitude,
			this.currentSearchCoordinate.longitude
		].map(p => round(p, MAX_GPS_DIGITS)).join(",") : null;
	}

	get currentSearchCoordinateHintString(): string | null {
		const {searchInput, currentSearchCoordinateString} = this;

		if (currentSearchCoordinateString && searchInput) {
			const [firstLine] = searchInput.displayLines;

			if (!/^(\-?[0-9\.]+,?){2}$/.test(firstLine.replace(/\s/g, ""))) {
				return firstLine;
			}
		}

		return null;
	}

	get currentPageCanonicalQuery(): Query {
		const query: Query = {};

		if (this.sessionAgent) {
			query["presented-by"] = this.sessionAgent.oid;
		}

		if (this.mlsValue) {
			query.mls_number = this.mlsValue;

			return query;
		} else if (this.propertySearchProperty) {
			query.property_id = this.propertySearchProperty.id.replace(/=*$/, "");
		} else if (this.currentSearchCoordinateString) {
			query.lat_lon = this.currentSearchCoordinateString;
		}

		const {nullableSearchFiltersIfDefault: searchFilters} = this;

		if (searchFilters.feedTypes) {
			query.feed = searchFilters.feedTypes;
		}

		if (searchFilters.statuses) {
			query.status = searchFilters.statuses;
		}

		if (searchFilters.transactionTypes) {
			query.transaction = searchFilters.transactionTypes;
		}

		if (searchFilters.ownershipTypes) {
			query.ownership = searchFilters.ownershipTypes;
		}

		if (searchFilters.propertyTypes) {
			query.property_type = searchFilters.propertyTypes;
		}

		if (searchFilters.styleAttachments) {
			query.attachment = searchFilters.styleAttachments.map(a => a === null ? nullString : a);
		}

		const price = [searchFilters.priceRangeMin, searchFilters.priceRangeMax]
		.map(p => typeof p === "number" ? p : "")
		.join(",")

		// has at least one value
		if (price.length > ",".length) {
			query.price = price;
		}

		const fee = [searchFilters.maintenanceFeeRangeMin, searchFilters.maintenanceFeeRangeMax]
		.map(p => typeof p === "number" ? p : "")
		.join(",");

		// has at least one value
		if (fee.length > ",".length) {
			query.fee = fee;
		}

		const beds = [searchFilters.bedroomsRangeMin, searchFilters.bedroomsRangeMax]
		.map(p => typeof p === "number" ? p : "")
		.join(",");

		// has at least one value
		if (beds.length > ",".length) {
			query.beds = beds;
		}

		const bedsPlus = [searchFilters.bedroomsPlusRangeMin, searchFilters.bedroomsPlusRangeMax]
		.map(p => typeof p === "number" ? p : "")
		.join(",");

		// has at least one value
		if (bedsPlus.length > ",".length) {
			query.beds_plus = bedsPlus;
		}

		const baths = [searchFilters.bathroomsRangeMin, searchFilters.bathroomsRangeMax]
		.map(p => typeof p === "number" ? p : "")
		.join(",");

		// has at least one value
		if (baths.length > ",".length) {
			query.baths = baths;
		}

		const kitchens = [searchFilters.kitchensRangeMin, searchFilters.kitchensRangeMax]
		.map(p => typeof p === "number" ? p : "")
		.join(",");

		// has at least one value
		if (kitchens.length > ",".length) {
			query.kitchens = kitchens;
		}

		const parkings = [searchFilters.parkingSpacesRangeMin, searchFilters.parkingSpacesRangeMax]
		.map(p => typeof p === "number" ? p : "")
		.join(",");

		// has at least one value
		if (parkings.length > ",".length) {
			query.parkings = parkings;
		}

		const garages = [searchFilters.garageSpacesRangeMin, searchFilters.garageSpacesRangeMax]
		.map(p => typeof p === "number" ? p : "")
		.join(",");

		// has at least one value
		if (garages.length > ",".length) {
			query.garages = garages;
		}

		const lot_size_width = [searchFilters.lotSizeWidthRangeMin, searchFilters.lotSizeWidthRangeMax]
		.map(p => typeof p === "number" ? p : "")
		.join(",");

		// has at least one value
		if (lot_size_width.length > ",".length) {
			query.lot_size_width = lot_size_width;
		}

		const lot_size_depth = [searchFilters.lotSizeDepthRangeMin, searchFilters.lotSizeDepthRangeMax]
		.map(p => typeof p === "number" ? p : "")
		.join(",");

		// has at least one value
		if (lot_size_depth.length > ",".length) {
			query.lot_size_depth = lot_size_depth;
		}

		const lot_size_area = [searchFilters.lotSizeAreaRangeMin, searchFilters.lotSizeAreaRangeMax]
		.map(p => typeof p === "number" ? p : "")
		.join(",");

		// has at least one value
		if (lot_size_area.length > ",".length) {
			query.lot_size_area = lot_size_area;
		}

		const interior_area = [searchFilters.interiorAreaRangeMin, searchFilters.interiorAreaRangeMax]
		.map(p => typeof p === "number" ? p : "")
		.join(",");

		// has at least one value
		if (interior_area.length > ",".length) {
			query.interior_area = interior_area;
		}

		const storeys = [searchFilters.storeysRangeMin, searchFilters.storeysRangeMax]
		.map(p => typeof p === "number" ? p : "")
		.join(",");

		if (storeys.length > ",".length) {
			query.storeys = storeys;
		}

		const days_back = [searchFilters.daysAtMarketAvailabilityRangeMin, searchFilters.daysAtMarketAvailabilityRangeMax]
		.map(p => typeof p === "number" ? p : "")
		.join(",");

		if (days_back.length > ",".length) {
			query.days_back = days_back;
		}

		if (searchFilters.hasOpenHouse) {
			query.open_house = "1";
		}

		if (searchFilters.hasWaterfrontAccess) {
			query.has_waterfront_access = "1";
		}

		if (searchFilters.viewerReactions && searchFilters.viewerReactions.length) {
			query.only_liked = "1";
		}

		const {polygonPolyline, savedListingSearch} = this;

		if (polygonPolyline) {
			query.polygon = encodeURIComponent(polygonPolyline);
		}

		if (savedListingSearch) {
			query.saved_listing_search_id = savedListingSearch.id.replace(/=*$/, "");
		}

		return query;
	}

	get currentPageNonCanonicalQuery(): Query {
		const query: Query = {};

		if (this.currentSearchCoordinateHintString) {
			query.lat_lon_hint = this.currentSearchCoordinateHintString;
		}

		if (!this.mapOnTop) {
			query.view = "cards";
		}

		return query;
	}

	get currentPageQuery(): Query {
		return {
			...this.currentPageCanonicalQuery,
			...this.currentPageNonCanonicalQuery,
		};
	}

	get currentPageLocation(): Location {
		return { path: "listings", query: this.currentPageQuery };
	}

	get canonicalUrl(): string {
		const protocol = (global as any as Window).location ? location.protocol : "https:";
		const url = new URL(this.$route.path, `${protocol}//${this.host}`);

		Object.entries(this.currentPageCanonicalQuery)
		.map(([key, value]): [string, string][] => {
			const valueArray = Array.isArray(value) ? value : [value];

			return valueArray
			.filter((val): val is string => typeof val === "string")
			.map((val): [string, string] => [key, val]);
		})
		.reduce((a, b) => a.concat(b))
		.forEach(([key, value]) => url.searchParams.append(key, value))

		return url.toString();
	}

	@Watch("currentPageLocation", {
		immediate: true,
	})
	onCurrentPageLocation(value: Location, oldValue: Location) {
		if (process.server) {
			return;
		}

		if (!oldValue || isEqual(value, oldValue)) {
			// don't push the same route
			return;
		}

		this.$router.push(value)?.catch(() => {});
	}

	get countClusterAnnotations() {
		const {clustersWithCounts} = this;

		if (!clustersWithCounts) {
			return [];
		}

		return clustersWithCounts
		.filter(cluster => cluster.count)
		.map((cluster) => {
			const {center: latLon, count, hullPolyline} = cluster;

			if (!latLon) {
				return null;
			}

			const coordinate = new mapkit.Coordinate(latLon.lat, latLon.lon);

			return {
				data: {
					id: `cluster_${coordinate.toString()}`,
					count,
					hullPolyline,
				},
				coordinate,
				clusteringIdentifier: null,
			}
		})
		.filter(isNotNull);
	}

	get listingAnnotations() {
		const {propertySearchListingWithError, allListingsWithErrors} = this;

		if (!this.mapkitLoaded) {
			return [];
		}

		const propertySearchPropertyListingId = propertySearchListingWithError?.id;

		return allListingsWithErrors
		.map(edge => {
			const {listing, id, errors} = edge;

			let latLon = listing?.property?.latLon || errors[0]?.extensions?.latLon;

			if (!latLon) {
				return null;
			}

			const coordinate = this.coordinateToMapkitCoordinate({latitude: latLon.lat, longitude: latLon.lon});

			return {
				data: {
					id,
					oid: listing?.oid,
				},
				coordinate,
				listing,
				errors,
				clusteringIdentifier: propertySearchPropertyListingId === id ? null : undefined,
			}
		})
		.filter(isNotNull)
		.reverse();
	}

	mounted() {
		this.preSetUpMap();
	}

	created() {
		this.searchAutocompleteDebounced = debounce(this.searchAutocomplete.bind(this), 350);
	}

	destroyed() {
		(this.searchAutocompleteDebounced as any as {cancel: () => void}).cancel();
	}

	preSetUpMap() {
		const {initialMapCoordinate, defaultMapCoordinateRegion} = this;

		// Set the starting region of the map
		if (initialMapCoordinate) {
			// if there is a starting coordinate in mind, use that to create the region
			let span = this.getSpanForMaxDegrees(DEFAULT_MAP_SPAN);
			this.mapRegion = makeCoordinateRegionPOJO(initialMapCoordinate, span);
		} else {
			// otherwise, default to the last/default region from the store
			this.mapRegion = defaultMapCoordinateRegion;
		}

		this.queryRegion = null;
	}

	async setUpMap() {
		const {initialMapCoordinate, defaultMapCoordinateRegion, currentSearchCoordinate, searchInput} = this;

		if (this.mlsValue) {
			return;
		}

		await this.$nextTick();

		this.mapkitRegion = this.getCurrentMapkitRegion();

		// after the map has rendered a frame, and we've gotten the actual latest region,
		// update the map to the desired region again
		// this is required because `getSpanForMaxDegrees` requires the dimensions of the map to be present
		if (initialMapCoordinate) {
			this.mapRegion = makeCoordinateRegionPOJO(initialMapCoordinate, this.getSpanForMaxDegrees(CLOSE_MAP_SPAN));
		} else {
			const span = defaultMapCoordinateRegion.span;
			const MAXIMUM_INITIAL_SPAN = DEFAULT_MAP_SPAN * 10;
			const spanAdjusted = Math.max(span.latitudeDelta, span.longitudeDelta) >= MAXIMUM_INITIAL_SPAN ? this.getSpanForMaxDegrees(MAXIMUM_INITIAL_SPAN) : defaultMapCoordinateRegion.span;
			const defaultMapCoordinateRegionAdjusted = makeCoordinateRegionPOJO(defaultMapCoordinateRegion.center, spanAdjusted);

			this.mapRegion = defaultMapCoordinateRegionAdjusted;
		}

		this.queryRegion = null;

		this.currentSearchCoordinate = currentSearchCoordinate;
		this.searchInput = searchInput;

		await this.$nextTick();

		if (!this.displayClusters) {
			this.queryRegion = this.mapRegion;
			this.currentSearchCoordinate = currentSearchCoordinate;
			this.searchInput = searchInput;
		}
	}

	get searchAutocompleteOptionsOrPlaceholder(): SearchMultiselectOptionsGroup[] {
		return this.searchAutocompleteOptions || [
			{
				section: "",
				results: [
					{
						type: "saved_listing_searches",
						displayLines: ["Saved Searches"]
					}
				]
			}
		]
	}

	async searchChangeHandler(query: string): Promise<void> {
		this.updatePotentiallyEmptySectionHeader();

		query = query.trim();

		if (!query) {
			// empty auto complete list
			this.searchAutocompleteOptions = null;

			return;
		}

		const results: SearchMultiselectOptionsGroup[] = [];

		if (MLS_REGEX.test(query)) {
			results.push({
				section: "MLS®#",
				results: [
					{
						type: "mls",
						displayLines: [query],
					}
				]
			});
		}

		if (query.length < 3) {
			return;
		}

		this.autocompleteIsLoading = true;

		this.latestSearchAutocompleteQuery = query;
		this.searchAutocompleteDebounced(query);

		if (results.length) {
			this.searchAutocompleteOptions = results;
		}
	}

	async searchAutocomplete(query: string): Promise<void> {
		const {search, mapkitRegion} = this;
		const region = mapkitRegion || this.getCurrentMapkitRegion();

		(this as any).$matomo?.trackEvent("Listings", "Search:Input", query);

		const mapkitSearch = search && new Promise<mapkit.SearchAutocompleteResult[]>((resolve, reject) => {
			const timeout = window.setTimeout(() => reject(new Error("Autocomplete timeout")), 4000);
			const searchOptions: mapkit.SearchOptions = {}

			if (region) {
				searchOptions.region = region;
			}

			search.autocomplete(query, (err, data) => {
				window.clearTimeout(timeout);

				if (err) {
					return reject(err);
				}

				return resolve(data.results);
			}, searchOptions);
		});

		let propertySearch;

		if (region && query.length > 4) {
			const center = region.center;

			propertySearch = new Promise<ApolloQueryResult<PropertiesByAddressResult>>(async (resolve, reject) => {
				const timeout = window.setTimeout(() => reject(new Error("Property search timeout")), 4000);

				const result = await this.$apollo.query<PropertiesByAddressResult, PropertiesByAddressVariables>({
					query: PropertiesByAddressQuery,
					variables: {
						search: query,
						point: {
							lat: center.latitude,
							lon: center.longitude,
						}
					},
				});

				window.clearTimeout(timeout);

				resolve(result);
			});
		}

		const [
			searchResultsSettledResult,
			propertyResultsSettledResult,
		] = await Promise.allSettled([
			mapkitSearch || Promise.resolve(null),
			propertySearch || Promise.resolve(null),
		]);

		let searchResults;
		let propertyResults;

		if (searchResultsSettledResult.status === "fulfilled") {
			searchResults = searchResultsSettledResult.value;
		}

		if (propertyResultsSettledResult.status === "fulfilled") {
			propertyResults = propertyResultsSettledResult.value;
		}

		// a new query may have been submitted before the promise has resolved.
		// therefore, we should ignore the results of this return;
		if (this.latestSearchAutocompleteQuery !== query) {
			return;
		}

		const results: SearchMultiselectOptionsGroup[] = [];

		// filter results with no coordinates (such as franchises)
		const coordinateOnlySearchResults = (searchResults || [])
		.filter((result): result is SearchAutocompleteResultWithCoordinate => Boolean(result.coordinate))
		.map((result): SearchMultiselectAutocompleteOption => ({
			type: "autocomplete",
			...result,
			autocomplete: result,
			coordinate: this.coordinateToCoordinate(result.coordinate)
		}));

		if (propertyResults?.data.propertiesByAddress.edges) {
			const propertySearchMultiselectOptions = propertyResults.data.propertiesByAddress.edges
			.map(edge => edge?.node)
			.filter((node): node is PropertiesByAddressNode => Boolean(node))
			.map((node): SearchMultiselectPropertyOption | null => {
				const lat_lon = node.latLon;

				if (!lat_lon) {
					return null;
				}

				return propertyNodeToPropertySearchInput(node);
			})
			.filter((node): node is SearchMultiselectPropertyOption => Boolean(node?.coordinate))

			if (propertySearchMultiselectOptions.length) {
				results.push({
					section: "Properties",
					results: propertySearchMultiselectOptions,
				});
			}
		}

		if (coordinateOnlySearchResults.length) {
			results.push({
				section: "Near",
				results: coordinateOnlySearchResults,
			});
		}

		if (results.length) {
			this.searchAutocompleteOptions = results;
		}

		this.autocompleteIsLoading = false;
	}

	@Watch("searchInput", {
		immediate: true,
	})
	async onSearchInputChange(val: SearchMultiselectOption | null, prev_val: SearchMultiselectOption | null) {
		if (!val) {
			this.searchAutocompleteOptions = null;
			return;
		}

		if (val.skip) {
			return;
		}

		if (val.type === "saved_listing_searches") {
			// show modal here
			this.searchInput = prev_val;

			if (!this.viewer) {
				if (process.server) {
					return;
				}

				if (window.confirm("You must log in to access your Saved Searches. Would you like to log in?")) {
					this.$router.push({path: "/login", query: {redirect: window.location.pathname + window.location.search}});
				}

				return;
			}

			this.$modal.show("saved-listing-search-picker");

			return;
		}

		const $matomo = (this as any).$matomo;

		let propertySearchProperty = null;
		let savedListingSearch = null;
		let propertySearchListingWithError = null;
		let currentSearchCoordinate = null;
		let mlsValue = null;
		let overlayCoordinates = null;

		// const { userLocationCoordinate } = this;

		if (val.type === "mls") {
			const firstLine = val.displayLines[0];

			$matomo?.trackSiteSearch(firstLine, "Listings:MLS");
			mlsValue = firstLine;

			// this.$store.dispatch("recordListingSearchSearchActorEvent", {
			// 	currentLocation: userLocationCoordinate ? {
			// 		lat: userLocationCoordinate.latitude,
			// 		lon: userLocationCoordinate.longitude,
			// 	} : null,
			// 	search: {
			// 		mlsNumber: {
			// 			mlsNumber: mlsValue,
			// 		},
			// 	},
			// } as RecordListingSearchSearchActorEventMutationVariables["input"]);

		} else if (val.type === "saved_listing_search") {
			$matomo?.trackSiteSearch(val.displayLines[0], "Listings:SavedListingSearch");

			const aSavedListingSearch = val.savedListingSearch;

			savedListingSearch = aSavedListingSearch;

			overlayCoordinates = this.overlayCoordinates
		} else if (val.type === "autocomplete") {
			$matomo?.trackSiteSearch(val.displayLines[0], "Listings:Address");

			const searchedCoordinate = await new Promise<mapkit.Coordinate>((resolve, reject) => {
				this.search?.search(val.autocomplete, (err, data) => {
					if (err) {
						return reject(err);
					}

					const [place] = data.places;

					if (!place) {
						return reject(new Error("No places found for search"));
					}

					resolve(place.coordinate);
				});
			});

			currentSearchCoordinate = this.coordinateToCoordinate(searchedCoordinate);

			// this.$store.dispatch("recordListingSearchSearchActorEvent", {
			// 	currentLocation: userLocationCoordinate ? {
			// 		lat: userLocationCoordinate.latitude,
			// 		lon: userLocationCoordinate.longitude,
			// 	} : null,
			// 	searchTerm: val.displayLines[0],
			// 	search: {
			// 		nearest: {
			// 			point: {
			// 				lat: searchedCoordinate.latitude,
			// 				lon: searchedCoordinate.longitude,
			// 			}
			// 		}
			// 	},
			// } as RecordListingSearchSearchActorEventMutationVariables["input"]);
		} else if (val.type === "property") {
			$matomo?.trackSiteSearch(val.displayLines[0], "Listings:Property");

			const result = await this.$apollo.query<ShortListingFromPropertyByIdResult, ShortListingFromPropertyByIdVariables>({
				query: ShortListingFromPropertyByIdQuery,
				variables: {
					id: val.property.id,
				},
				errorPolicy: "all",
			});

			if (result.data.property?.__typename === "Property") {
				const listingsWithErrors = groupListingConnectionQueryResultDataAndErrors({
					listings: result.data.property.listings,
					errors: result.errors
				}, ["property", "listings"]);

				propertySearchProperty = result.data.property;
				propertySearchListingWithError = listingsWithErrors[0];

				// TODO: should this be handled elsewhere?
				const span = this.getSpanForMaxDegrees(CLOSE_MAP_SPAN);
				const latLon = propertySearchProperty.latLon!;

				this.animateOrSetRegion(makeCoordinatePOJO(latLon.lat, latLon.lon), span);

				// this.$store.dispatch("recordListingSearchSearchActorEvent", {
				// 	currentLocation: userLocationCoordinate ? {
				// 		lat: userLocationCoordinate.latitude,
				// 		lon: userLocationCoordinate.longitude,
				// 	} : null,
				// 	searchTerm: val.displayLines[0],
				// 	search: {
				// 		property: {
				// 			propertyId: propertySearchProperty.id,
				// 		},
				// 	},
				// } as RecordListingSearchSearchActorEventMutationVariables["input"]);
			}

		} else {
			currentSearchCoordinate = this.coordinateToCoordinate(val.coordinate);

			// this.$store.dispatch("recordListingSearchSearchActorEvent", {
			// 	currentLocation: userLocationCoordinate ? {
			// 		lat: userLocationCoordinate.latitude,
			// 		lon: userLocationCoordinate.longitude,
			// 	} : null,
			// 	searchTerm: val.displayLines[0],
			// 	search: {
			// 		nearest: {
			// 			point: {
			// 				lat: val.coordinate.latitude,
			// 				lon: val.coordinate.longitude,
			// 			},
			// 		},
			// 	},
			// } as RecordListingSearchSearchActorEventMutationVariables["input"]);
		}

		this.trackingUserLocation = false;
		this.mlsValue = mlsValue;
		this.propertySearchProperty = propertySearchProperty;
		this.propertySearchListingWithError = propertySearchListingWithError;
		this.currentSearchCoordinate = currentSearchCoordinate;
		this.overlayCoordinates = overlayCoordinates;
		this.savedListingSearch = savedListingSearch;
	}

	@Watch("savedListingSearch", {
		immediate: true
	})
	async onSavedListingSearchChange(val: SavedListingSearchNode | null, prev_val: SavedListingSearchNode | null) {
		if (prev_val && !val) {
			this.searchInput = null;
		}

		if (val) {
			await this.$apollo.mutate<MarkSavedListingSearchExecutionResult, MarkSavedListingSearchExecutionVariables>({
				mutation: MarkListingSearchExecutionMutation,
				variables: {
					input: {
						savedListingSearch: val.id,
					}
				}
			}).catch(e => {
				this.$sentry?.captureException(e);
			});

			// const { userLocationCoordinate } = this;

			// this.$store.dispatch("recordListingSearchSearchActorEvent", {
			// 	currentLocation: userLocationCoordinate ? {
			// 		lat: userLocationCoordinate.latitude,
			// 		lon: userLocationCoordinate.longitude,
			// 	} : null,
			// 	search: {
			// 		savedListingSearch: {
			// 			savedListingSearchId: val.id,
			// 		},
			// 	},
			// } as RecordListingSearchSearchActorEventMutationVariables["input"]);
		}
	}

	@Watch("mlsValue", {
		immediate: true,
	})
	onMlsValueChange(value: string | null) {
		if (value) {
			this.listings = null;
			this.listingsWithErrors = null;
			this.propertySearchProperty = null;
			this.propertySearchListingWithError = null;
			this.trackingUserLocation = false;
			this.overlayCoordinates = null;
			this.currentSearchCoordinate = null;

			this.searchInput = {
				type: "mls",
				displayLines: [value],
			};
		}
	}

	private getSpanForMaxDegrees(degrees: number) {
		const {mapSpanRatio} = this;

		const spanX = mapSpanRatio > 1 ? degrees : degrees * mapSpanRatio;
		const spanY = mapSpanRatio < 1 ? degrees : degrees / mapSpanRatio;

		return makeCoordinateSpanPOJO(spanX, spanY);
	}

	private animateOrSetRegion(coordinate: ICoordinate, span: ICoordinateSpan, setQueryRegion: boolean = true) {
		const map = this.getMapInstance();

		if (map) {
			const mapkitCoordinate = this.coordinateToMapkitCoordinate(coordinate)!;
			const mapkitSpan = this.coordinateSpanToMapkitCoordinateSpan(span)!;
			const region = new mapkit.CoordinateRegion(mapkitCoordinate, mapkitSpan);

			if (setQueryRegion) {
				this.mapkitQueryRegion = region;
			}

			map.setRegionAnimated(region);
		} else {
			this.mapRegion = makeCoordinateRegionPOJO(coordinate, span);

			if (setQueryRegion) {
				this.queryRegion = this.mapRegion;
			}
		}
	}

	@Watch("currentSearchCoordinate")
	onCurrentSearchCoordinateChange(value: ICoordinate | null, _oldValue: ICoordinate | null) {
		if (value) {
			const span = this.getSpanForMaxDegrees(CLOSE_MAP_SPAN);

			// let queryRegion be set on the next map region change
			this.queryRegion = null;
			this.mlsValue = null;
			this.listings = null;
			this.listingsWithErrors = null;

			this.searchInput = this.searchInput || {
				type: "search",
				coordinate: value,
				displayLines: [[value.latitude, value.longitude].map(p => round(p, MAX_GPS_DIGITS)).join(", ")],
			};

			this.animateOrSetRegion(value, span);
		}
	}

	@Watch("listingAnnotations")
	onAnnotationsChanged(annotations: mapkit.MarkerAnnotation[], oldAnnotations: mapkit.MarkerAnnotation[]) {
		let coordinateSpan: mapkit.CoordinateSpan | null = null;

		if (annotations?.length) {
			if (oldAnnotations?.[0]?.data.id === annotations[0]?.data.id) {
				// we're already focused on the map region with this annotation, skip further map adjustments
				// this includes when we're paging through listings data
				return;
			}

			const propertySearchListingId = this.propertySearchListingWithError?.id ?? null;
			const propertyListingAnnotation = propertySearchListingId !== null && annotations.find(annotation => annotation.data.id === propertySearchListingId) || null;

			if (!(this.mlsValue || propertyListingAnnotation)) {
				// return early
				return;
			}

			const annotationCoordinates = annotations.map(annotation => annotation.coordinate);

			const coordinates = annotationCoordinates.filter(isNotNull);

			if (coordinates.length > 1) {
				const allLats = coordinates.map(coord => coord.latitude);
				const allLons = coordinates.map(coord => coord.longitude);

				const maxLat = Math.max(...allLats);
				const minLat = Math.min(...allLats);

				const maxLon = Math.max(...allLons);
				const minLon = Math.min(...allLons);

				const maxDelta = Math.max((maxLat - minLat), (maxLon - minLon));

				coordinateSpan = this.coordinateSpanToMapkitCoordinateSpan(this.getSpanForMaxDegrees(maxDelta * 1.1));
			}

			if (!coordinateSpan) {
				coordinateSpan = this.coordinateSpanToMapkitCoordinateSpan(this.getSpanForMaxDegrees(CLOSE_MAP_SPAN));
			}

			if (propertyListingAnnotation) {
				const coord = propertyListingAnnotation.coordinate;
				const pojoCoord = makeCoordinatePOJO(coord.latitude, coord.longitude);

				this.animateOrSetRegion(pojoCoord, coordinateSpan!, false);
			} else if (this.mlsValue) {
				// if these are listings for an MLS search, the map should be centered on the average position of the listing(s)
				const avgLat = mean(annotationCoordinates.map(coord => coord.latitude));
				const avgLon = mean(annotationCoordinates.map(coord => coord.longitude));
				const pojoCoord = makeCoordinatePOJO(avgLat, avgLon);

				this.animateOrSetRegion(pojoCoord, coordinateSpan!, false);
			}
		}
	}

	getMapInstance(): mapkit.Map | null {
		const {listingsMap} = this;

		return listingsMap?.map ?? null;
	}

	getCurrentMapkitRegion() {
		const map = this.getMapInstance();

		return map && map.region || null;
	}

	onRegionChangeEnd(event: any) {
		const {target} = event;
		const map = target as mapkit.Map;
		const {region} = map;

		this.mapkitRegion = region;

		this.storeSearchCoordinateRegion(region);

		// if there is no query region yet, and we're no longer in "display clusters" mode,
		// set the query region to the current map region
		if (!this.mapkitQueryRegion && !this.displayClusters) {
			this.mapkitQueryRegion = this.mapkitRegion;
		}
	}

	onUserLocationChange(event: any) {
		const {coordinate} = event;

		if (this.trackingUserLocation) {
			this.mapkitUserLocationCoordinate = coordinate;
		}
	}

	@Watch("userLocationCoordinate")
	onUserLocationCoordinateChange(coordinate: ICoordinate | null, _oldCoordinate: ICoordinate | null) {
		if (coordinate && !_oldCoordinate) {
			this.searchInput = null;
			this.mlsValue = null;

			this.currentSearchCoordinate = null;
			this.overlayCoordinates = null;

			const span = this.getSpanForMaxDegrees(CLOSE_MAP_SPAN);

			this.animateOrSetRegion(coordinate, span);
		}
	}

	@Watch("trackingUserLocation", {
		immediate: true,
	})
	onTrackingUserLocationChange(value: boolean) {
		if (!value) {
			this.userLocationCoordinate = null;
		} else {
			// show current location annotation all the time
			this.showsUserLocation = true;

			const map = this.getMapInstance();

			if (map && map.userLocationAnnotation) {
				this.mapkitUserLocationCoordinate = map.userLocationAnnotation.coordinate;
			}
		}
	}

	async onFindLocationClick(_event: MouseEvent) {
		this.trackingUserLocation = false;
		this.mlsValue = null;
		this.propertySearchProperty = null;
		this.savedListingSearch = null;
		this.propertySearchListingWithError = null;
		this.currentSearchCoordinate = null;
		this.overlayCoordinates = null;
		this.mapkitQueryRegion = null;
		this.searchInput = null;

		await this.$nextTick();

		this.trackingUserLocation = true;
		this.onTrackingUserLocationChange(true);
	}

	onSelectCluster(event: any) {
		const clusterAnnotation = event.target as mapkit.Annotation;
		const ids = clusterAnnotation.memberAnnotations.map(a => a.data.id);

		this.filteredListingIds = ids;
	}

	onDeselectCluster(_event: any) {
		this.filteredListingIds = null;
	}

	onSearchHereClick(_event: MouseEvent) {
		const region = this.mapkitRegion || this.getCurrentMapkitRegion();

		if (!region) {
			return;
		}

		this.mlsValue = null;
		this.propertySearchProperty = null;
		this.savedListingSearch = null;
		this.propertySearchListingWithError = null;
		this.trackingUserLocation = false;
		this.currentSearchCoordinate = null;
		this.overlayCoordinates = null;
		this.mapkitQueryRegion = region;
		this.searchInput = null;
	}

	onMapkitUserLocationError(event: GeolocationPositionError) {
		switch (event.code) {
			case 1: // PositionError.PERMISSION_DENIED:
				console.log("geo permission denied");
				break;
			case 2: // PositionError.POSITION_UNAVAILABLE:
				console.log("unable to acquire position");
				break;
			case 3: // PositionError.TIMEOUT:
				console.log("unable to acquire position");
				break;
			case 4: // Error.MAPKIT_NOT_INITIALIZED
				console.log("mapkit not initialized");
				break;
		}

		this.trackingUserLocation = false;
		this.mapkitQueryRegion = this.mapkitRegion;
	}

	@Watch("hoveredListingId")
	onHoveredListingInChange(value: string) {
		const className = "card-hovered";

		if (!value) {
			// call querySelectorAll, as there may be multiple elements matching this description:
			// e.g. when clusters are re-evaluated (on the fly),
			// the old cluster may still be in the DOM and would get matched first
			this.$el.querySelectorAll(".listing-cluster-annotation.card-hovered")
			.forEach(el => el.classList.remove(className));
		} else {
			this.$el.querySelectorAll(`.listing-cluster-annotation[data-listing-ids*=id${value.replace(/=/g, "")}]`)
			.forEach(el => el.classList.add(className));
		}
	}

	countClusterAnnotationClick(hullPolyline: string, _event: MouseEvent) {
		const map = this.getMapInstance();

		if (!map) {
			return;
		}

		const polygonCoords = polyline.decode(hullPolyline);

		const coords = polygonCoords.map(([lat, lon]) => new mapkit.Coordinate(lat, lon));

		const debug = false; // disable to see overlay for debugging

		const overlayStyle = new mapkit.Style({
			strokeColor: "#F00",
			strokeOpacity: 1,
			lineWidth: 64, // this is important, as the width is taken into account of the padding
			lineJoin: "round",
			fillColor: "#F00",
			fillOpacity: 0.2,
		});

		const polygonOverlay = new mapkit.PolygonOverlay(coords, {
			visible: debug,
			style: overlayStyle,
		});

		const minimumSpan = this.coordinateSpanToMapkitCoordinateSpan(this.getSpanForMaxDegrees(CLOSE_MAP_SPAN))!;

		map.showItems([polygonOverlay], {
			animate: true,
			// padding: map.padding,
			minimumSpan,
		})

		if (!debug) {
			// showItems adds the overlay to the map, but we don't want it, so remove it immediately (unless we want debugging)
			map.removeOverlay(polygonOverlay)
		}
	}

	onSearchFiltersChange() {
		// hide filters
		this.showFilters = false;

		// set the query region to the current region
		const region = this.mapkitRegion;

		if (region) {
			this.mapkitQueryRegion = region;
		}
	}

	@Action("storeSearchCoordinateRegion") storeSearchCoordinateRegion!: (region: ICoordinateRegion) => void;

	beforeRouteEnter(_to: Route, from: Route, next: Function) {
		if (!from) {
			return next();
		}

		// TODO: this needs to be updated to reference the ID and not the OID
		const {listing_id} = from.params;

		if (!listing_id) {
			return next();
		}

		next((vm: ListingsIndexPage) => {
			// we have the oid, but now need the id
			try {
				const result = vm.$apollo.getClient().readQuery<ListingQueryResult, ListingQueryVariables>({
					query: ListingQuery,
					variables: {
						id: listing_id,
					}
				});

				const id = result?.listing?.id;

				if (id) {
					vm.selectedListingId = id;
				}
			} catch (e) {}
		});
	}

	beforeRouteUpdate(_to: Route, _from: Route, next: Function) {
		const map = this.getMapInstance();

		queryRegion = !this.displayClusters && this.queryRegion || null;

		if (map) {
			this.mapRegion = map.region;
		}

		next();
	}

	onAnnotationSelect(_event: AnnotationSelectEvent) {
		// Skip this behaviour as the performance is degraded on mapkit annotation
		// not sure why so many annotation vues are undergoing updateRender, since only two are truly changing
		//this.selectedListingId = event.target.data.id;
	}

	onAnnotationDeselect(_event: AnnotationSelectEvent) {
		//this.selectedListingId = "";
	}

	onMapClick() {
		this.closeMultiselect();
	}

	closeMultiselect() {
		const {multiselect: _multiselect} = this.$refs;
		const multiselect = _multiselect as any;

		if (!multiselect) {
			return;
		}

		if (multiselect.isOpen) {
			multiselect.deactivate();
		}
	}

	private async updatePotentiallyEmptySectionHeader() {
		await this.$nextTick();

		const multiselect = this.$refs.multiselect as any as Multiselect;

		const [emptyGroupOptionElement] = [...multiselect.$el.querySelectorAll(".multiselect__option.multiselect__option--group") as unknown as Array<Element>]
		.map(e => e.closest(".multiselect__element"))
		.filter((e): e is NonNullable<typeof e> => !!e);

		if (emptyGroupOptionElement) {
			emptyGroupOptionElement.classList[emptyGroupOptionElement.textContent?.trim() ? "remove" : "add"]("empty")
		}
	}

	onMultiselectOpen() {
		this.updatePotentiallyEmptySectionHeader();
	}

	isDrawing: boolean = false;
	overlayCoordinates: ICoordinate[] | null = null;
	cachedOverlayCoordinates: ICoordinate[] | null = null;

	get overlayMapkitCoordinates(): mapkit.Coordinate[] | null {
		if (!this.mapkitLoaded) {
			return null;
		}

		if (!this.overlayCoordinates) {
			return null;
		}

		return this.overlayCoordinates
		.map(coord => this.coordinateToMapkitCoordinate(coord))
		.filter((coord): coord is NonNullable<typeof coord> => coord !== null);
	}

	get polylineOverlayCoordinates(): mapkit.Coordinate[] | null {
		if (!this.isDrawing) {
			return null;
		}

		return this.overlayMapkitCoordinates;
	}

	get polygonOverlayCoordinates(): mapkit.Coordinate[] | null {
		if (this.isDrawing) {
			return null;
		}

		return this.overlayMapkitCoordinates;
	}

	get polygonPolyline(): string | null {
		// NOTE: this getter is called constantly while drawing. Possibly because even though `polygonOverlayCoordinates` returns null each time, it is being changed(?)
		// NOTE 2: the `mapkitLoaded` check is to fallback to overlayCoordinates when in a SSR environment
		const overlayCoordinates = (this.mapkitLoaded ? this.polygonOverlayCoordinates : this.overlayCoordinates) || this.cachedOverlayCoordinates;

		if (!overlayCoordinates) {
			return null;
		}

		const [firstCoordinate] = overlayCoordinates;
		const lastCoordinate = overlayCoordinates[overlayCoordinates.length - 1];

		let normalizedOverlayCoordinates = [...overlayCoordinates];

		if (!(
			firstCoordinate.latitude === lastCoordinate.latitude &&
			firstCoordinate.longitude === lastCoordinate.longitude
		)) {
			normalizedOverlayCoordinates.push(firstCoordinate);
		}

		return polyline.encode(
			normalizedOverlayCoordinates
			.map(c => [c.latitude, c.longitude])
		);
	}

	enableDrawingMode(_event: MouseEvent) {
		this.cachedOverlayCoordinates = this.overlayCoordinates;
		this.overlayCoordinates = null;
		this.isDrawing = true;
	}

	addCoordinateForMouseEvent(event: MouseEvent | TouchEvent) {
		if (!this.overlayCoordinates) {
			throw new Error("overlayCoordinates is null");
		}

		const map = this.getMapInstance();

		if (!map) {
			return;
		}

		const [touch = null] = !(event instanceof MouseEvent) && Array.from(event.touches) || [];
		const {clientX, clientY} = touch || event as MouseEvent;

		const scrollTop = document.scrollingElement && document.scrollingElement.scrollTop || 0;
		const domPoint = new DOMPoint(clientX, clientY + scrollTop);
		const coordinate = map.convertPointOnPageToCoordinate(domPoint);

		const lastCoordinate = this.overlayCoordinates[this.overlayCoordinates.length - 1];

		if (lastCoordinate && Math.hypot(coordinate.longitude - lastCoordinate.longitude, coordinate.latitude - lastCoordinate.latitude) < 0.00005) {
			return;
		}

		this.overlayCoordinates.push(coordinate);
	}

	onDrawingSurfaceMousedown(event: MouseEvent | TouchEvent) {
		event.preventDefault();
		this.overlayCoordinates = [];
		this.addCoordinateForMouseEvent(event);
	}

	onDrawingSurfaceMousemove(event: MouseEvent) {
		if (!this.isDrawing || !this.overlayCoordinates) {
			return;
		}

		event.preventDefault();

		this.addCoordinateForMouseEvent(event);
	}

	onDrawingSurfaceMouseup(event: MouseEvent | TouchEvent) {
		if (!this.isDrawing) {
			return;
		}

		event.preventDefault();

		this.isDrawing = false;
		this.cachedOverlayCoordinates = null;

		if (!this.overlayCoordinates) {
			throw new Error("overlayCoordinates is null");
		}

		if (this.overlayCoordinates.length < 3) {
			// not enough points
			this.overlayCoordinates = null;
			return;
		}

		const simplified = simplify(
			this.overlayCoordinates.map(coord => ({x: coord.longitude, y: coord.latitude})),
			0.00002,
			true,
		);

		const simplifiedMapkitCoordinates = simplified.map(({x, y}) => new mapkit.Coordinate(y, x));
		const [first] = simplifiedMapkitCoordinates;

		simplifiedMapkitCoordinates.push(first);

		this.overlayCoordinates = simplifiedMapkitCoordinates;

	}

	@Watch("polygonOverlayCoordinates", {
		immediate: true,
	})
	onPolygonOverlayCoordinatesChanged(val: mapkit.Coordinate[] | null) {
		if (val) {
			const isPolylineFromSavedSearch = this.savedListingSearch?.polyline === this.polygonPolyline;

			this.trackingUserLocation = false;
			this.currentSearchCoordinate = null;
			this.propertySearchProperty = null;
			this.savedListingSearch = isPolylineFromSavedSearch ? this.savedListingSearch : null;
			this.propertySearchListingWithError = null;
			this.searchInput = isPolylineFromSavedSearch ? this.searchInput : null;
			this.queryRegion = null;

			// next ensures that the overlay was drawn onto the map,
			// AND that the page has rendered
			this.$nextTick(() => {
				const map = this.getMapInstance();

				if (map) {
					const minimumSpan = this.coordinateSpanToMapkitCoordinateSpan(this.getSpanForMaxDegrees(CLOSE_MAP_SPAN / 4))!;

					map.showItems(map.overlays, {
						animate: true,
						minimumSpan,
					});
				}
			});


			// if (!isPolylineFromSavedSearch) {
			// 	const { userLocationCoordinate } = this;

			// 	this.$store.dispatch("recordListingSearchSearchActorEvent", {
			// 		currentLocation: userLocationCoordinate ? {
			// 			lat: userLocationCoordinate.latitude,
			// 			lon: userLocationCoordinate.longitude,
			// 		} : null,
			// 		search: {
			// 			polylinePolygon: {
			// 				polyline: this.polygonPolyline,
			// 			}
			// 		},
			// 	} as RecordListingSearchSearchActorEventMutationVariables["input"]);
			// }
		}
	}
}
