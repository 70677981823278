
import { Component, Prop, Vue } from "vue-property-decorator";

@Component<SavedListingSearchMapThumbnail>({})
export default class SavedListingSearchMapThumbnail extends Vue {
	@Prop({type: String, required: true})
	polyline!: string;

	@Prop({type: Number, required: false, default: 50})
	width!: number;

	@Prop({type: Number, required: false, default: 50})
	height!: number;

	private get image_src_base_url(): URL {
		const {polyline, width, height} = this;

		const widthNormalized = Math.min(Math.max(50, width), 640);
		const heightNormalized = Math.min(Math.max(50, height), 640);

		const mapSnapshotURL = new URL(`/mk_snapshot`, this.$config.HOST_WEB);

		const overlays = [
				{
				points: polyline,
				strokeColor: "9934ff",
				lineWidth: 2,
			}
		];

		mapSnapshotURL.searchParams.append("overlays", JSON.stringify(overlays));

		mapSnapshotURL.searchParams.set("center", "auto");
		mapSnapshotURL.searchParams.set("size", `${widthNormalized}x${heightNormalized}`);//50 is the smallest size
		mapSnapshotURL.searchParams.set("poi", "0");
		mapSnapshotURL.searchParams.set("scale", "2");

		return mapSnapshotURL;
	}

	get image_src_1x(): string {
		const {image_src_base_url} = this;

		const url = new URL(image_src_base_url);

		url.searchParams.set("scale", "1");

		return url.toString();
	}

	get image_src_2x(): string {
		const {image_src_base_url} = this;

		const url = new URL(image_src_base_url);

		url.searchParams.set("scale", "2");

		return url.toString();
	}
};
