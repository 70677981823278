import { Location } from "vue-router";
import { SavedListingSearchFragment } from "~/gql-typings/SavedListingSearchFragment";

type Query = Exclude<Location["query"], undefined>;

type IntRange = {
	readonly __typename: "IntRange";
	readonly begin: number | null;
	readonly end: number | null;
}

type FloatRange = {
	readonly __typename: "FloatRange";
	readonly begin: number | null;
	readonly end: number | null;
}

type NumericRange = IntRange | FloatRange;

export default function savedListingSearchToQuery(savedListingSearch: SavedListingSearchFragment) {
	const query: Query = {};
	const {polyline} = savedListingSearch;

	const polylineUrlSafe = polyline
		.replace(/\//g, "_")
		.replace(/\+/g, "-")
		.replace(/=/g, "");

	query.polygon = polylineUrlSafe;

	if (savedListingSearch.feedTypes) {
		query.feed = [...savedListingSearch.feedTypes];
	}

	if (savedListingSearch.statuses) {
		query.status = [...savedListingSearch.statuses];
	}

	if (savedListingSearch.transactionTypes) {
		query.transaction = [...savedListingSearch.transactionTypes];
	}

	if (savedListingSearch.ownershipTypes) {
		query.ownership = [...savedListingSearch.ownershipTypes];
	}

	if (savedListingSearch.styleAttachments) {
		query.attachment = [...savedListingSearch.styleAttachments];
	}

	if (savedListingSearch.propertyTypes) {
		query.property_type = [...savedListingSearch.propertyTypes];
	}

	const numberRangeToQueryValue = (range: NumericRange | null): string | null => {
		if (range === null) {
			return null;
		}

		const value = [range.begin, range.end]
		.map(p => typeof p === "number" ? p : "")
		.join(",");

		// has at least one value
		return value.length > ",".length ?
			value :
			null;
	};

	const price = numberRangeToQueryValue(savedListingSearch.priceRange)

	if (price) {
		query.price = price;
	}

	const fee = numberRangeToQueryValue(savedListingSearch.maintenanceFeeRange)

	if (fee) {
		query.fee = fee;
	}

	const beds = numberRangeToQueryValue(savedListingSearch.bedroomsRange)

	if (beds) {
		query.beds = beds;
	}

	const bedsPlus = numberRangeToQueryValue(savedListingSearch.bedroomsPlusRange)

	if (bedsPlus) {
		query.beds_plus = bedsPlus;
	}

	const baths = numberRangeToQueryValue(savedListingSearch.bathroomsRange)

	if (baths) {
		query.baths = baths;
	}

	const kitchens = numberRangeToQueryValue(savedListingSearch.kitchensRange)

	if (kitchens) {
		query.kitchens = kitchens;
	}

	const parkings = numberRangeToQueryValue(savedListingSearch.parkingSpacesRange)

	if (parkings) {
		query.parkings = parkings;
	}

	const garages = numberRangeToQueryValue(savedListingSearch.garageSpacesRange)

	if (garages) {
		query.garages = garages;
	}

	const lot_size_width = numberRangeToQueryValue(savedListingSearch.lotSizeWidthRange)

	if (lot_size_width) {
		query.lot_size_width = lot_size_width;
	}

	const lot_size_depth = numberRangeToQueryValue(savedListingSearch.lotSizeDepthRange)

	if (lot_size_depth) {
		query.lot_size_depth = lot_size_depth;
	}

	const lot_size_area = numberRangeToQueryValue(savedListingSearch.lotSizeAreaRange)

	if (lot_size_area) {
		query.lot_size_area = lot_size_area;
	}

	const interior_area = numberRangeToQueryValue(savedListingSearch.interiorAreaRange)

	if (interior_area) {
		query.interior_area = interior_area;
	}

	const storeys = numberRangeToQueryValue(savedListingSearch.storeysRange);

	if (storeys) {
		query.storeys = storeys;
	}

	const days_back = numberRangeToQueryValue(savedListingSearch.daysAtMarketAvailabilityRange);

	if (days_back) {
		query.days_back = days_back;
	}

	if (savedListingSearch.hasOpenHouse) {
		query.open_house = "1";
	}

	if (savedListingSearch.hasWaterfrontAccess) {
		query.has_waterfront_access = "1";
	}

	// TODO: how to represent this one better. As an array?
	if (savedListingSearch.viewerReactions && savedListingSearch.viewerReactions.length) {
		query.only_liked = "1";
	}

	return query;
}
