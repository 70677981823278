// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/icon_checkmark_select.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/icon_checkmark_hover.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkbox-item[data-v-4e82ee0c]{display:flex;flex-direction:column;justify-content:flex-start;align-items:stretch;flex-direction:row}input[type=checkbox][data-v-4e82ee0c]{flex:0 0 auto;-webkit-appearance:none;-moz-appearance:none;appearance:none;width:21px;height:21px;border-radius:21px;box-shadow:inset 0 0 0 2px var(--outer-space);border:none;outline:none;background-position:center;background-size:16px 16px;background-repeat:no-repeat}.on-dark input[type=checkbox][data-v-4e82ee0c]{box-shadow:inset 0 0 0 2px #fff}@media(hover: hover){input[type=checkbox][data-v-4e82ee0c]:hover{background-color:rgba(0,0,0,.05)}.on-dark input[type=checkbox][data-v-4e82ee0c]:hover{background-color:hsla(0,0%,100%,.15)}}input[type=checkbox][data-v-4e82ee0c]:active{background-color:rgba(0,0,0,.1)}input[type=checkbox][data-v-4e82ee0c]:checked{background-color:var(--veronica);box-shadow:none;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.on-dark input[type=checkbox][data-v-4e82ee0c]:active{background-color:hsla(0,0%,100%,.2)}.on-dark input[type=checkbox][data-v-4e82ee0c]:checked{background-color:#fff;box-shadow:none;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}input[type=checkbox][data-v-4e82ee0c]:disabled{pointer-events:none;filter:grayscale(1) opacity(0.25)}input[type=checkbox]:disabled+label[data-v-4e82ee0c]{filter:grayscale(1) opacity(0.25)}input[type=checkbox]+label[data-v-4e82ee0c]{margin-left:10px;font-weight:400}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
